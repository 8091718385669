import React from 'react';
import '../project.css';
import ScrollTop from "react-scrolltop-button";

class Hospice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    // console.log("thisis blackboard")
    return (

      <div>

        <div className="narrow">
          <p className="projectName">Burr Puzzle</p>
          <p className="h2">A Hospice for cats</p>
          <br/>
          <p className="projectinfo">2014 Fall<br/>
            Location:NA<br/>
            Individual work
            <br/>
          </p>



          <p className="generaltext">To raise more public concern for vagrant cats, the proposal is wooden structure that provides shelter, food supply and opportunities of adoption.
            The facility is flexible according to the need of exhibition. The structure is dismountable by the development and use of a traditional mortise joint type-burr puzzle, or Luban lock.
            By creating such space it tries to raise more attention from society.
            Vagrant cats are mostly abandoned or feral. Living in an tough condition, they don't have access to clean food resources and shelter and it leads to shorter lives. This design provides a shelter and playground for vagrant cats where they can also be fed and adopted. The cat boxes are placed in a grid structure which uses dismountable joints.
          </p>

        </div>

        <div className="narrow">
          <ul className="img_list">
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HOSPICE%2Frender%20copy.png?alt=media&token=c9ca4ed2-a656-45d0-a406-793ef3a96870"/>
                <span className="text-content">EXTERIOR PERSPECTIVE</span>
              </a>
            </li>
            <br/>
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HOSPICE%2Faxon.png?alt=media&token=51e0023c-1340-49c5-a6d7-60026863290a"/>
                <span className="text-content">AXON AND JOINT DETAILS</span>
              </a>
            </li>
            <br/>
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HOSPICE%2Fdiagram%20copy.png?alt=media&token=b0dc4578-442c-49bf-a70b-a6b7dc44c116"/>
                <span className="text-content">DIAGRAM</span>
              </a>
            </li>

            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HOSPICE%2Fbehavior%20copy.png?alt=media&token=ed72629e-1bd4-474d-9870-bf7c28cfaa3f"/>
                <span className="text-content">BEHAVIOR STUDY</span>
              </a>
            </li>
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HOSPICE%2Fshelter%20copy.png?alt=media&token=ff1159fa-ef94-4c0b-90c1-87c6fb1c8625"/>
                <span className="text-content">SHELTER TYPOLOGIES</span>
              </a>
            </li>
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HOSPICE%2Frender2.jpg?alt=media&token=456066f0-a527-45f8-afe8-4de41b6404d5"/>
                <span className="text-content">INSIDE PERSPECTIVE</span>
              </a>
            </li>
          </ul>
        </div>

        <ScrollTop text="Top"/>

      </div>

    );

  }
}

export default Hospice;