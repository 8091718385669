import React from 'react';
import './About.css';

import Card from 'react-bootstrap/Card'

import Jumbotron from 'react-bootstrap/Jumbotron'

class About extends React.Component {
  render() {
    return (
      <div className="">
        <div className="row">

          <div className="col-4">
            <p className="left" id="name">Kent Wu
            </p>
          </div>



          <div className="col-8">
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fportrait.png?alt=media&token=c14bff9b-f324-4503-af15-793746dc3aa1"
                 className="photo"/>
            <svg width="30" height="30" viewBox="0 0 30 30"><rect className="rectangle" width="20" height="24"/>
            </svg>
            <p className="text">
              Hi, I am Kent.
              I received comprehensive education of design and I am working on my master's on computer science. This website was built from my previous design portfolio.
              I am passionate about both design and technology. I enjoy the sense of accomplishment when a project is done.
              <br/>
              I am actively seeking for full-stack opportunities upon graduation on 2020 summer/ co-op opportunities for 2020 spring.
              If you would like to talk, feel free to email me@ wuyang@wustl.edu

            </p>

            <h2 className="text-new-one">
              Thanks for visiting!
            </h2>



            <div className="row">
              <div className="col">
                <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fgithub-logo.png?alt=media&token=8f98d3c5-8333-4aaf-9a5f-38f7ae70b776"></img>
                <span className="sm_text">https://github.com/jlskent</span>
              </div>
              <div className="col">
                <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Flinkedin.png?alt=media&token=7328ee3c-c151-4337-80d0-1860d07f1465"></img>
                <span className="sm_text">https://www.linkedin.com/in/yangstark/</span>
              </div>

              <div className="col">
                <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fgmail.png?alt=media&token=e0f0f5ac-6f7c-47b8-9a36-d79701ea3851"></img>
                <span className="sm_text">wuyang@wustl.edu</span>
              </div>



            </div>









          </div>

        </div>



      </div>

    );
  }


}

export default About;