import React from 'react';
import './Contact.css';



class Contact extends React.Component {
  render() {
    return (
      <div className="">

      </div>

    );
  }


}

export default Contact;