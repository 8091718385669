import React from 'react';
import './Footer.css';



class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <div id="texts">
          <p>©copy2016 by Yang WU copyright reserved by author</p>
        </div>
      </div>
    );
  }


}

export default Footer;