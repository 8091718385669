import React from 'react';
import '../project.css';
import GoTop from '../GoTop/GoTop'
import ScrollTop from "react-scrolltop-button";


class BoPay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    return (

      <div>
        <p className="projectName">BO Pay</p>
        <div className="wide">
          <ul className="img_list">
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BOPAY%2Fheader.png?alt=media&token=29215bc8-9939-44f9-aa46-4361fafdf080"/>
              </a>
            </li>
          </ul>
        </div>


        <div className="narrow">
          <p className="generaltext">There are two types of periodical bills. The first ones are the bills you have to pay by yourself every month, like your rent. The second are the bills you don't want anymore yet they are automatically paid, like coursera, Netflix or Match. BO Pay is a lightweight app designed for Bank of America that allow users to manage their bills. The framework of ebill is already available in BOA. By understanding the painpoints and through interaction design, users of Bank of America can easily understand what's going on with their account, pay bills by swiping fingers, and explore more options of the service provided by sponsored trades.
          </p>
          <div className="row">
            <div className="col-6">
              <iframe src="https://marvelapp.com/58a6a36?emb=1" width="452" height="901" allowTransparency="true"
                      frameBorder="0"></iframe>
            </div>
            <div className="col-6">
              <iframe src="https://marvelapp.com/7b4gb20?emb=1" width="452" height="901" allowTransparency="true" frameborder="0" align="right"></iframe>
            </div>
          </div>

          <span className="text-content" align="right">Interactive models: Demo of paying periodical bills and adding a bill</span>

          <p className="title">The Process</p>
          <ul className="img_list">
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BOPAY%2Fsenarioexport.png?alt=media&token=66a51b44-b080-4e89-88e0-2d97322978d8"/>
                <span className="text-content" align="right">The two Types of periodical bills: subscriptions on a lark and bills that have to be paid
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; How to pay: with internet or in person
                </span>
              </a>
            </li>
          </ul>
          <p className="title_secondary">How to pay like Tinder?</p>

          <p className="generaltext">After setting the design goal I created low-fi wire-frames and used paper-prototyping as a usablity test in an early stage. The main focus is:
          </p>
          <ul className="points_list">
            <li>How easy or intuitive is the process</li>
            <li>Fixing the problems and create an intuitive navigation</li>
          </ul>
          <p className="generaltext">Interview with testers also helped to define the design space better. For example, by testing out the current bill payment system using BOA website, it is found very hard to add a new bill simply because there is too much information to type in. However, since it is about paying the bill, it should work pretty well with very simple input and a good authentication. So the design is focused on how to pay bills intuitively and how to add a bill with multiple, simple ways.
          </p>

          <p className="title_secondary">How to create more opportunities?</p>
          <p className="generaltext">Subscription has become a popular pattern of purchasing services. Internet services, real-life services, utilities and even tax all need periodical payment. However it is hard to manage them with different trades and it is one-to-one relationship. With introduction of BO Pay offers, it is easier to subscribe the best service or compare the price.
          </p>
          <p className="generaltext">To do next--Hi-fi testing had good feedback in terms of usability. Although design is considered complete in terms of product design. Interviewee included more pain points which can lead to ideas of further refining:
          </p>
          <ul className="points_list">
            <li>Adding bills from scratch</li>
            <li>Stronger notification of scheduled subscription payment</li>
          </ul>
          <ul className="img_list">
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BOPAY%2Fusabilitytest.png?alt=media&token=42a44e7e-8ca2-44c3-b384-cb8367e4b00f"/>
                <span className="text-content">Usability testing</span>
              </a>
            </li>
            <p className="title">Persona</p>
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BOPAY%2Fpersona.png?alt=media&token=db210178-f75e-49d1-9224-ebfd5d2a0279"/>

              </a>
            </li>
          </ul>
        </div>
        <p className="title">Deliverable</p>
        <div className="wide">
          <ul className="img_list">
            <li>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BOPAY%2Fcontent.png?alt=media&token=e02b5d8f-30e5-4467-8014-fcfd9c3f312a"/>
              </a>
            </li>
          </ul>
        </div>
        <ScrollTop text="Top"/>
      </div>

  );

  }
}

export default BoPay;