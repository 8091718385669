import React from 'react';
import '../project.css';
import './billlizad.css';

import ScrollTop from "react-scrolltop-button";
import Jumbotron from 'react-bootstrap/Jumbotron'


class BillLizard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    // console.log("thisis blackboard")
    return (

      <div>
        <div className="narrow">
          <p className="projectName">Bill lizard</p>
            <p>
            A campaign of lowering health-care costs was already started. Under potential legislation or changes in medical system, it is important to understand the billing data.
            Our project is a prototype website that helps to visualize, analyze data of procedures, bills, and charges in hospitals.
            </p>
            <p className="projectinfo">Team: Ben, Ramzi<br/>
              Time: summer 2019
            </p>
            <div className="link_to_proj">
              <p className="title">link to the project</p>
              <a href = "https://health-pricing.web.app/">https://health-pricing.web.app/</a>
            </div>

            <div>
              <p className="title" id = "demo_scene">Demo scene</p>
              <p className="title_secondary">Uploading multiple files and visualization</p>
              <iframe src="https://player.vimeo.com/video/359211208" width="100%" height="800" frameBorder="0"
                      allow="fullscreen" allowFullScreen></iframe>
              <p className="title_secondary">Filtering, grouping, and sorting</p>
              <iframe src="https://player.vimeo.com/video/359211200" width="100%" height="800" frameBorder="0"
                      allow="autoplay; fullscreen" allowFullScreen></iframe>
              <span className="text-content">In-browser data manipulation (similar to python pandas)</span>

              <p className="title_secondary">Statistics and viewing</p>
              <iframe src="https://player.vimeo.com/video/359211191" width="100%" height="800" frameBorder="0"
                      allow="autoplay; fullscreen" allowFullScreen></iframe>
              <span className="text-content">Interactively viewing each groups, find whisker low, whisker high, outliers etc. Click to pin table data and show stats.</span>


              {/*<p className="title">The aims of our tool are:*/}
              {/*</p>*/}
              {/*<p>*/}
              {/*First of all, it is a diagnostic tool for hospitals to get more payment. We can visualize and see for example which provider bills better for a Cpt code. We can find out which procedure has better adjustments. We can detect outliers and calculate variance so potentially we find out aspects to be improved.*/}
              {/*</p>*/}

              {/*<p>*/}
              {/*Also, it can be a research tool. By visualizing and analyzing the data, more importantly, by comparing over time and different hospitals, we begin to figure out some medical trends. For example, procedure A's charge average is gets lower but the variance gets higher. Does it mean that we have better and cheaper technology over time or different locations? Does the prices reflect social change or government policy change? If there is a significant difference of variance on same procedure among two hospitals, does it mean that doctors in one hospital are more familiar with medical codes?*/}
              {/*</p>*/}

            </div>
            <div>

              <p className="title">Features:
              </p>
              <ul>
                <li>
                  Supports uploading multiple csv files to compare data.
                </li>
                <li>
                  Browse by Cpt Code, Provider name, and Procedure name. For each category, we can generate box plots with clicks.
                </li>
              </ul>

              <p className="title">
                How to use:
              </p>
              <ul>
                <li>
                  Upload a csv file.
                </li>
                <li>
                  Optionally, It supports for filtering as pre-processing, considering some procedures have quantities larger than one or some are pro bonos.
                </li>
                <li>
                  Choose a category out of Cpt Code, Provider name, and Procedure name to browse by. It will read the csv file and list all values in the category. Then select one item from the list.
                </li>

                <li>
                  For the values listed, choose sorting options like frequency, average, variance, etc.
                </li>
                <li>
                  After clicking on one of items from the list, a graph is generated. We can now see a box plot for each group. When hovering, we see stats on the box plot(whisker low, whisker high, outliers etc).
                </li>
                <li>
                  Too show raw data from graph, click on a bar that represents a group.
                </li>
              </ul>



              <p className="title">Conclusion and Future work</p>
              <p className="generaltext">
                In the first week of the project, we had a discussion and decided to make data-manipulation in-browser. There are trade-offs but we considered a couple of reasons. First we can avoid passing data around and causing potential security issues. Second it is fast when running on client-side memory. It also saves the resources from back end and it it easier to implement(only having user auth on back end).
                <br/>
                Now the challenge here was actually processing and passing data with data-frames(data forge), d3 and React. After this was set up after a few weeks. There was another trade-off we thought about. Do we want to store the operations and run scripts on data later, or do we want it to be more interactive? So the idea is that we want to have the interaction to manipulate data because it feels more intuitive. But when it comes to multiple data frames, it is a good idea to store the chaining commands and run batch tasks.
              </p>
              <p className="generaltext">
                Next steps of work, if received more funding, we would focus more on:
              </p>
              <ul>
                <li>
                  Creating a web service to set up environment so it can be compatible to varies data sets. Because each hospital may have a different format of data, for the first time of use, a web service that helps user to match the columns would be a good option to introduce more compatibility.
                </li>
                <li>
                  Improving comparing features. For example, a line chart would be more ideal if the focus is more on the change of data.
                </li>
                <li>
                  Implementing user authentication, payment features for membership for production mode. And consider using React-Redux.
                </li>
                <li>
                  Improving data security.
                </li>
            </ul>

          </div>

        </div>


        <ScrollTop text="Top"/>

    </div>

  );

  }
}

export default BillLizard;