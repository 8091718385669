import firebase from 'firebase/app'

import 'firebase/storage'


const config = {
  apiKey: "AIzaSyAVbubwhZe9h73byHzAvTrwHGLdRwVf5R8",
  authDomain: "portfolio-a2f3d.firebaseapp.com",
  databaseURL: "https://portfolio-a2f3d.firebaseio.com",
  projectId: "portfolio-a2f3d",
  storageBucket: "portfolio-a2f3d.appspot.com",
  messagingSenderId: "185598831356",
  appId: "1:185598831356:web:24ee9b4fbc57cb1e"
};

firebase.initializeApp(config);



var storage = firebase.storage();



export {
  storage, firebase

}