import React from 'react';
import './ImageNavigation.css';
import Blackboard from "../BlackBoard/Blackboard";

import AHubAFlagshipStore from "../AHubAFlagshipStore/AHubAFlagshipStore";
import DesignThinking from "../DesignThinking/DesignThinking";
import BoPay from "../BoPay/BoPay";
import Hospice from "../Hospice/Hospice";
import DegreeProject from "../DegreeProject/DegreeProject";
import UnexpectedCity from "../UnexpectedCity/UnexpectedCity";
import CourseWorkSample from "../CourseWorkSample/CourseWorkSample";


import BillLizard from "../BillLizard/BillLizard";

import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";



class ImageNavigation extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      shouldHideList: false

    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount(){
    // this.setState({  shouldHideList: false  })
    // console.log(this.props.location.myprops);
  }



  handleClick(e){
    // hide list
    console.log("handling click");

    this.setState({  shouldHideList: true  })
    this.setState({home_btn_clicked: false});

    // if(this.state.shouldHideList === false){
    //   this.setState({  shouldHideList: true  })
    // }else{
    //   this.setState({  shouldHideList: false  })
    // }


    // this.setState({ currentProject: e.target.value})
  }

  async componentWillReceiveProps(nextProps) {

    if (nextProps.location.myprops.goto_img_list){

      this.setState({home_btn_clicked: true});
      this.setState({shouldHideList: false});


    }

    console.log(this.state.shouldHideList);

  }


  renderList(){
      // console.log("true");
      return (
        <div className="container">
          <Router>
            <div className ={this.state.shouldHideList ? 'hidden' : ''}>

            <ul className="img-list">
              <li>
                <Link to="/bopay">
                    <figure onClick={this.handleClick}>
                      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_bopay.png?alt=media&token=54ba6714-ce34-4597-9318-889de4a1c89e"/>
                      <figcaption><p>BO Pay</p></figcaption>
                    </figure>
                </Link>
              </li>
              <li>
                <Link to="/blackboard">
                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_blackboard.png?alt=media&token=02c0a14d-6ffc-4f68-9662-580cffd5bc60"/>
                    <figcaption><p>Blackboard +</p></figcaption>
                  </figure>
                </Link>

              </li>
              <li>
                <Link to='/billlizard'>
                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2FArtboard%201%404x.png?alt=media&token=a8431230-c01b-4c27-b076-549a4f71716e"/>
                      <figcaption><p>Bill Lizard</p></figcaption>
                  </figure>
                </Link>
              </li>
              <li>
              <Link to='/worksample'>
                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_sample.png?alt=media&token=c51b910e-7395-48d8-aca3-c32fd2be69d9"/>
                    <figcaption><p>Course Worksamples</p></figcaption>
                  </figure>
              </Link>
              </li>

              <li>
                <Link to='/designthinking'>
                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_dt.png?alt=media&token=84177f56-7fd6-42a6-bba4-db7a30856020"/>
                    <figcaption><p>Design Thinking</p></figcaption>
                  </figure>
                </Link>
              </li>
              <li>
                <Link to="/degreeproject">

                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_dp.jpeg?alt=media&token=b4a40407-044a-4555-b223-079f0d88a42f"/>
                    <figcaption><p>Degree Project</p></figcaption>
                  </figure>
                </Link>
              </li>
              <li>
                <Link to="/unexpectedcity">

                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_unexpectedcity.png?alt=media&token=75ae5134-44da-49bc-93bb-7a3c1523637e"/>
                    <figcaption><p>Unexpected city</p></figcaption>
                  </figure>
                </Link>
              </li>
              <li>
                <Link to="/ahubaflagshipstore">
                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_ahubaflagshipstore.png?alt=media&token=baecb683-d50b-4b3d-9b73-3cfac0cb87a7"/>
                    <figcaption><p>A hub,a flagship store</p></figcaption>
                  </figure>
                </Link>
              </li>
              <li>
                <Link to='/hospice'>
                  <figure onClick={this.handleClick}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fthumb%2Fthumb_hospice.png?alt=media&token=c144e7be-72ab-410c-9131-ab1df1460b4c"/>
                    <figcaption><p>Hospice</p></figcaption>
                  </figure>
                </Link>
              </li>
            </ul>
            </div>
            <div className="full-width-div">
              <div className ={this.state.home_btn_clicked ? 'hidden' : ''}>
                <Switch>
                  {/*<Route exact path="/" component={ImageNavigation} />*/}
                  <Route path="/billlizard" component={BillLizard} />
                  <Route path="/blackboard"  component={Blackboard} />
                  <Route path="/ahubaflagshipstore" component={AHubAFlagshipStore} />
                  <Route path="/designthinking" component={DesignThinking} />
                  <Route path="/degreeproject" component={DegreeProject} />
                  <Route path="/bopay" component={BoPay} />
                  <Route path="/worksample" component={CourseWorkSample} />
                  <Route path="/hospice" component={Hospice} />
                  <Route path="/unexpectedcity" component={UnexpectedCity} />
                </Switch>
              </div>
            </div>
          </Router>
        </div>

      );

  }

  render() {
    return(
      <div>
      {this.renderList()}
      </div>
  );
  }


}

export default ImageNavigation;