import React from 'react';
import '../project.css';
import './degreeproject.css';
import ScrollTop from "react-scrolltop-button";

class DegreeProject extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    // console.log("thisis blackboard")
    return (

      <div>



        <div className="narrow">
          <p className="projectName">St.Louis Data Fusion Center</p>
          <p className="title">Degree Project</p>


        <p className="projectinfo">2017 Fall<br/>
        Individual Work<br/>
        Site Location:St.Louis<br/>
        Professor:Chandler Ahrens<br/>Wustl degree project publication entry
        </p>

      </div>

        <div id="scroll">
          <img id="scrollImg" src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fsp1.jpg?alt=media&token=024f8bfd-f53d-4897-b797-bc4aa6002acf"/>
          <span className="text-content">AA Section 309 cm x 91 cm</span>
        </div>

    <div className="narrow">

      <p className="generaltext">
        As a shrinking, post-industrial city that used to be the fourth largest city in U.S, St.Louis is now embracing opportunities in high-tech industry. There are already incubator space like Cortex and T-rex. And large companies are building their headquarters in St.Louis for the sake of lower cost, like Microsoft.
        The project derives from a governmental proposal called "St.Louis Smart City Challenge".
        The plan includes building a governmental facility, to be more specific, a data center with research labs that dedicates to monitor and improve city traffic by researching and coding.

          The site is located in north St.Louis where there are areas full of vacant land and empty houses. For this reason large companies are buying out a chunk of small parcels to build their facilities. The reason to choose my site is not only because of the cheap land price and a data center can actually locate in anywhere, but there is  also inspiration from a project called NGA. NGA(National Geographical Agency) is actually a spy agency. This huge project is going to take over 100 acres of land in this area and expel 200 residents by tearing down or moving their houses. It ends up creating a tabula rasa in the urban fabric. The agency with fences monitors and gathers data as intelligence for government decision makers. So the idea is really to set up a shift, a different idea against the NGA approach, by creating an open and accessible data center right next to it.

            So the design challenge becomes testing out how open a data center could be. Traditional data center is an enclosed facility with heavy security and mechanical system. The proposal is a coexistence of a data center and a co-working space where there is public office. The two programs  have separate entrances and are only visually connected to each other. Existing buildings on site are kept intact. And a diagonal path is carved out through the building not only to separate the two programs, but also to create a pathway that invites people deep into the building site, where there are courtyards and where visitors can have a glimps into the glass as they go through. The data research center is accommodated in a U-shaped space. On south-east corner there is cantilevered space where visitors can have interaction with media. And on north-west side there is a small day care for the community. Finally, the rest of the building is co-working space and courtyards. The ground floor with office is quite open since the majority of the servers are located on second floor. And the office gets additional light through punched holes in the pitched roof.

              Structurally it is a mixation of precast concrete and steel structure. Three bars of concrete bearing walls are designed as servant space and the space inbetween is more open with non-bearing walls, steel columns and trusses. Metal panels are wrapping the facade as well as the porous roof which can be seen from the street. In terms of mechanical system, for the cooling, air system is utilized. However, water system with radiant floor is used for heat exchange. So heat gathered from the server racks are used to heat up the rest of space thus data center is thermally interactive with the co-working space as well.
      </p>
tubal

      <ul className="img_list">
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fsiteplan.jpg?alt=media&token=6183b5f2-9d5c-4f5e-8c73-96370b3b681c"/>
            <span className="text-content"><span>Site Plan</span></span>
          </a>
        </li>
        <li>
          <a >
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fp1.jpg?alt=media&token=92d68e0f-c7cf-400a-bdd6-0a8fa2904550"/>
            <span className="text-content">Perspective</span>
          </a>
        </li>
        <li>
          <a >
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fplan1.png?alt=media&token=76204fc7-bba0-48fb-8069-55215c4d9d0c"/>
            <span className="text-content">Ground Floor Plan</span>
          </a>
        </li>
        <li>
          <a >
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fplan2.png?alt=media&token=e7887b4b-6f30-4094-868e-8b2e8b1ad062"/>
            <span className="text-content">Second Floor Plan</span>
          </a>
        </li>
        <li>
          <a >
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fp3.jpg?alt=media&token=9332b50f-90a5-4069-80ae-88d56b6ae87f"/>
            <span className="text-content">Perspective A</span>
          </a>
        </li>
        <li>
          <a >
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fp4.jpg?alt=media&token=38610e0e-8d68-47b7-a51b-efc0b8052061"/>
            <span className="text-content">Perspective B</span>
          </a>
        </li>

        <li>
          <a >
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2F6.jpg?alt=media&token=64bc1740-48c4-49e9-9d8a-c1174e45298e"/>
            <span className="text-content">Perspective C</span>
          </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fsp1.jpg?alt=media&token=024f8bfd-f53d-4897-b797-bc4aa6002acf">
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fsp1.jpg?alt=media&token=024f8bfd-f53d-4897-b797-bc4aa6002acf"/>
            <span className="text-content">AA Section 309 cm x 91 cm(click to view)</span>
          </a>
        </li>
        <li>
            <a href="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fsp2.jpg?alt=media&token=ee2d63f6-e0d4-4031-8fd0-358a6fd86384">

              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DP%2Fsp2.jpg?alt=media&token=ee2d63f6-e0d4-4031-8fd0-358a6fd86384"/>

              <span className="text-content">BB Section 309 cm x 91 cm(click to view)</span>
            </a>
        </li>
      </ul>

    </div>
        <ScrollTop text="Top"/>


    </div>

  );

  }
}

export default DegreeProject;