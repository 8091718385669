import React from 'react';
import './CV';



class CV extends React.Component {
  render() {
    return (
      <div className="">
        <ul className="img-list">
          <li>
            <a className="column1" href="BOPAY.html">
              <figure>
                <img src="images/BOPAY/thumb.png"/>
                <figcaption><p>BO Pay</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="BLACKBOARD.html">
              <figure>
                <img src="images/BLACKBOARD/thumb.png"/>
                <figcaption><p>Blackboard +</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="GEEKPARK.html">
              <figure>
                <img src="images/GEEKPARK/thumb.png"/>
                <figcaption><p>Geek Park</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="SAMPLE.html">
              <figure>
                <img src="images/SAMPLE/thumb.png"/>
                <figcaption><p>Course Worksamples</p></figcaption>
              </figure>
            </a>
          </li>

          <li>
            <a className="column1" href="DT.html">
              <figure>
                <img src="images/DT/thumb.png"/>
                <figcaption><p>Design Thinking+</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="DP.html">
              <figure>
                <img src="images/DP/thumb.png"/>
                <figcaption><p>Degree Project</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="UNEXPECTEDCITY.html">
              <figure>
                <img src="images/UNEXPECTEDCITY/sectionstrumb.png"/>
                <figcaption><p>Unexpectd city</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="AHUBAFLAGSHIPSTORE.html">
              <figure>
                <img src="images/AHUBAFLAGSHIPSTORE/thumb.png"/>
                <figcaption><p>A hub,a flagship store</p></figcaption>
              </figure>
            </a>
          </li>
          <li>
            <a className="column1" href="BURRPUZZLE.html">
              <figure>
                <img src="images/BURRPUZZLE/thumb.png"/>
                <figcaption><p>Hospice</p></figcaption>
              </figure>
            </a>
          </li>
        </ul>
      </div>

    );
  }


}

export default CV;