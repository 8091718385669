import React from 'react';
import '../project.css';

class Blackboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    // console.log("thisis blackboard")
    return (

      <div>


        <div className="wide">
          <p className="projectName">Blackboard +</p>
          <ul className="img_list">
            <li>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BLACKBOARD%2Fheader.png?alt=media&token=3d2b91b7-307b-4464-a06a-497304baa2cb"/>
            </li>
          </ul>
        </div>

        <div className="narrow">
          <p className="title">Introducing Blackboard+</p>
          <p className="generaltext">Blackboard is a LMS (Learning Management System) web application. It has a lot of tools
            and modules that make it powerful yet hard to use. LMS is now used for K12, higher education and business
            realms and Blackboard has a number of rivals. The advantage of Blackboard is that it is well-integrated with
            SIS(Student Information System). Comparing to piazza who seeks users, Blackboard has its audience. The focus
            is to make the most of it by improving usability and pursuing explicit strategic goals.
          Blackboard+ is hierarchical in terms of course content. It allows school faculty to upload, manage their
          course content easily. It creates a interactive and accessible online course documentation and also helps
          managing students and scheduled events. Blackboard+ reduces multiple tools or media needed for professors and
          improves current course-managing experience.
          </p>

        <p className="title">The existing application</p>

        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BLACKBOARD%2Fexisting.png?alt=media&token=f822c312-695e-4389-9585-af9337401c06"/>
            <span className="text-content">Existing web application</span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BLACKBOARD%2Fbrandanalysis.png?alt=media&token=86a9ee7a-e6e6-46f7-87e2-c727730b16df"/>
            <span className="text-content">An analysis of similar products</span>
          </a>
        </li>

        <p>The current web application has 6 main functions:</p>
        <ul>
          <li>Find course material</li>
          <li>Turn in assignments</li>
          <li>Get notification</li>
          <li>View grades</li>
          <li>For instructors to give grades</li>
          <li>For instructors to upload materials</li>
        </ul>


        <p className="title">Pain points and design scheme</p>
        <p>
          By sketching out the journey map of a Blackboard user, I found there are more than plenty of small problems
          can be addressed. For example, the non-hierarchical layout, the difficulty of passing information between
          people. After some research with LMS products, I found that there already products like Piazza which is doing
          well on passing information as a school version of slack. So the priority as a product designer is not about
          the usability but about envisioning the goal or hierarchy of Blackboard.</p>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BLACKBOARD%2Fjourneymap.png?alt=media&token=8dd3926e-33ee-4668-a697-f5d85ef9dbdb"/>
            <span className="text-content">Blackboard user journey map</span>
          </a>
        </li>
        <p className="title_secondary">Coursera+Weebly? A vision from user journey </p>

        <p>
          Some students complain that they can not view the materials easily. Some professors have the need to create
          and document their course materials. A computer science professor, for instance, may write their own course
          webpage while other professors may just use Blackboard, their flash drive or Dropbox. With the capability of
          webpage builders like Weebly, it is possible to make an easy-to-make course website that's well-suited to the
          need of school faculty.
        </p>
        <p className="title_secondary">From product standpoint</p>

        <p>
          MOOC is getting popular however the resource is confined in a way. The vision is that course materials in
          universities are valuable yet neglected. And there aren't any products in the market now that are really
          designed for faculty. If the application can benefit professors to easily create interactive course contents
          with modularized format. These resources can be edited, reviewed and possibly published. It opens the future
          opportunity to create a community of educational resources.
        </p>

        <ul className="img_list">
          <p className="title">Storyboard</p>
          <li>
            <a>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/BLACKBOARD%2Fstoryboard.png?alt=media&token=7b923f0b-370c-450b-9704-d08863c03e11"/>
              <span className="text-content">Story Board</span>
            </a>
          </li>
          <title className="title">Prototype</title>

        </ul>
        <p className="title_secondary">Demo of managing materials and people using Blackboard+</p>
        <p>
          After entering the course tab of current application, the courses are listed in a editable format. A user can see course information, upload and edit different forms of content with the toolbox. The course content is displayed clearly with course schedule. User can also easily manage TA/RA by granting them access to certain files(like assignment files or slides). The final product is a complete online course documentation for faculty which they can choose to publish to online community.
        </p>

    </div>

        <iframe src="https://player.vimeo.com/video/250935093?autoplay=1&loop=1&autopause=0" width="100%" height="800"
            frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>


      </div>

  );

  }
}

export default Blackboard;