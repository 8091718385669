import React from 'react';
import '../project.css';
// import ScrollTop from "react-scrolltop-button";
import { Slide } from 'react-slideshow-image';
import { Zoom } from 'react-slideshow-image';
import {firebase, storage} from "../firbase";

class DesignThinking extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }












  render() {


    // var pathReference = storage.ref('images/stars.jpg');
    var gsReference = storage.refFromURL('gs://portfolio-a2f3d.appspot.com/DT/3.png')


    const images = [
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F0.png?alt=media&token=732d2029-08c0-4e7c-ae09-06e5da16eb29',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F1.png?alt=media&token=1130227e-3439-4f6a-ab08-334a7cf8b53b',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F2.png?alt=media&token=2e9b1339-c034-4a49-9a82-a82203041d69',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F3.png?alt=media&token=4c7f2482-be53-4801-90b0-fe9535ba9c19',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F4.png?alt=media&token=53e55e43-2cec-4b41-9f7a-d65db1040dc5',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F5.png?alt=media&token=c7ed8177-5070-4f93-94cd-731b8c301b3b',

      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F6.png?alt=media&token=93b067c9-7843-44ef-9387-001c693c6138',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F7.png?alt=media&token=c3702929-2c4f-4086-be30-87d62e14b3be',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F8.png?alt=media&token=43231738-0e27-424b-9dcc-defc503ca6f5',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F9.png?alt=media&token=41d16804-1fe6-4224-b619-e8b51acab797',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F10.png?alt=media&token=39459e72-9edb-42a3-a559-3c1acfad1389',

      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F11.png?alt=media&token=d9a8e5fe-7831-4ce2-97c6-6030c46d2d5b',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F12.png?alt=media&token=39be3498-af81-490d-94c3-982a63b472df',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F13.png?alt=media&token=44df0d75-40ca-452e-9985-89ca92093fd4',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F14.png?alt=media&token=0db96b7a-cc1d-4123-8afa-256338744fa5',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F15.png?alt=media&token=64f70b27-9683-4d77-91b2-a7b562031575',

      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F16.png?alt=media&token=da2a696a-e275-4db1-888c-a85287e2e98a',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F17.png?alt=media&token=e2cfda72-756e-47f2-9a8f-196c5492c727',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F18.png?alt=media&token=0ae8ec05-03b3-4a4d-b276-84e988834198',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F19.png?alt=media&token=9e596614-1c2d-4f48-bc95-8cca5898724d',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F20.png?alt=media&token=1cef4244-d0f8-41ec-ad67-7e3200e96ed6',

      //  21
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F21.png?alt=media&token=6090a002-c055-4a32-958e-87570b4f8dc6',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F22.png?alt=media&token=0bb554e8-6caf-4215-8167-8f82f87c30c1',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F23.png?alt=media&token=2f500144-6a3c-4f49-aa35-42894f4d4732',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F24.png?alt=media&token=92f77739-7230-4a3f-bb61-2dd6beb354b0',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F25.png?alt=media&token=08caeef9-b0da-4d2c-b339-ec73fa6648f7',

      //  26
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F26.png?alt=media&token=21a2e30a-f4e1-437e-990d-47bff15e87f8',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F27.png?alt=media&token=b5024b42-1176-4eba-8fd9-ac4df6b40d80',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F28.png?alt=media&token=17cd92ec-abe3-4e0d-ad0f-8446f29d8711',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F29.png?alt=media&token=f80dea9e-11b4-417b-a89b-a294560cc100',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F30.png?alt=media&token=22c40b64-6c20-44d2-8487-1c25a01de53c',
      'https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/DT%2F31.png?alt=media&token=8c4dd0a4-e38b-43fc-a477-05b799a44a7f'


    ];

    const zoomOutProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      scale: 1.5,
      arrows: true
    }

    const Slideshow = () => {
      return (
        <div className="slide-container">
          <Zoom {...zoomOutProperties}>
            {
              images.map((each, index) => <img key={index} style={{width: "100%"}} src={each}/>)
            }
          </Zoom>
        </div>
      )


    }


    // console.log("thisis blackboard")
    return (

      <div className='narrow'>
        <p className="projectName">Design thinking</p>
        <p className="generaltext">A research booklet for degree project and architectural topic</p>
        {Slideshow()}
      </div>

  );


  }
}

export default DesignThinking;