import React from 'react';
import './Navigation.css';
import About from "../About/About";
import CV from "../CV/CV";
import Contact from "../Contact/Contact";
import {Nav,Navbar,NavDropdown} from 'react-bootstrap';
// import App from '../App'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/ButtonToolbar'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ImageNavigation from "../ImageNavigation/ImageNavigation";

// import App from '../App';



import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";



class Navigation extends React.Component {

  renderMenu(){
    console.log("hi")
  }

  render() {
    return (
      <div className="row">

        {/*<Router>*/}
          {/*<div className="col-9">*/}
            {/*<img id='headerpic' src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2FHEADER.png?alt=media&token=3800c91b-ee7b-480f-a348-3dbe2de85227" alt="Wu Yang"/>*/}
          {/*</div>*/}
          {/*<div className="col-3">*/}
            {/*<Nav pullRight className = "flex-column" variant="tabs">*/}
              {/*<Nav.Item>*/}
                {/*<Nav.Link><Link to="/" className = "link">Home</Link></Nav.Link>*/}
              {/*</Nav.Item>*/}
              {/*<Nav.Item>*/}
                {/*<Nav.Link><Link to="/about" className = "link">GoTop</Link></Nav.Link>*/}
              {/*</Nav.Item>*/}
              {/*<Nav.Item>*/}
                {/*<Nav.Link><Link to="/cv" className = "link">CV</Link></Nav.Link>*/}
              {/*</Nav.Item>*/}
              {/*<Nav.Item>*/}
                {/*<Nav.Link><Link to="/contact" className = "link">Contact</Link></Nav.Link>*/}
              {/*</Nav.Item>*/}
            {/*</Nav>*/}
          {/*</div>*/}

          {/*<Switch>*/}
            {/*<Route exact path="/" component={ImageNavigation} />*/}
            {/*<Route path="/about" component={GoTop} />*/}
            {/*<Route path="/cv" component={CV} />*/}
            {/*<Route path="/contact" component={Contact} />*/}
          {/*</Switch>*/}

        {/*</Router>*/}

      </div>

    );
  }


}

export default Navigation;


  // {/*<img id='headerpic' src="images/other/HEADER.png" alt="Wu Yang"/>*/}
  //
  // {/*<div id="header">*/}
  // {/**/}
  // {/**/}
  // {/**/}
  // {/*<a class="frontpage" href="index.html">Home</a>*/}
  // {/*<a class="frontpage" href="ABOUT.html">GoTop</a>*/}
  // {/*<a class="frontpage" href="CV.html">CV</a>*/}
  // {/*<a class="frontpage" href="CONTACT.html">BoPay</a>*/}
  // {/*</div>*/}