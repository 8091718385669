import React from 'react';
import logo from './logo.svg';
import './App.css';
import Footer from "./Footer/Footer";
import Navigation from "./Navigation/Navigation";
import ImageNavigation from "./ImageNavigation/ImageNavigation";
import { storage } from 'firebase';
import Blackboard from "./BlackBoard/Blackboard";
import {Nav,Navbar,NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// import YourLink from './YourLink'


import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import About from "./About/About";
import CV from "./CV/CV";
import Contact from "./Contact/Contact";
// import Component

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      goto_img_list : false
    };
  }

  goToHomePage = () => {
    this.setState({goto_img_list: true})
  };



  render(){



    return (
      <div className="App">
        <div className="container">
          <div className="row">

            <Router>
              <div className="col-10">
                <img id='headerpic' src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2FHEADER%20copy.png?alt=media&token=953d5ed9-fed3-4f8e-a1ae-06e4b8a278d4" alt="Wu Yang"/>
              </div>
              <div className="col-2" id = "nav">
                {/*flex-column*/}
                <Nav pullright className = "flex-column" variant="pills">
                  <Nav.Item>
                    <Link className = "" to={{ pathname: "/", myprops: {goto_img_list :true} }}className = "link">Home</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to="/about" className = "link">About</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to="/github" className = "link">Git</Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Link to="/contact" className = "link">Contact</Link>
                  </Nav.Item>
                </Nav>

              </div>

              <Switch>
                <Route exact path="/" component={ImageNavigation} />
                <Route path="/about" component={About} />
                <Route path="/github" component={() => {
                  window.location.href = 'https://github.com/jlskent';
                  return null;
                }}/>
                <Route path="/contact" component={Contact} />
              </Switch>

            </Router>

          </div>

        </div>
        {/*<ImageNavigation/>*/}

        <div className = "footer">
          {/*<Footer></Footer>*/}
        </div>

      </div>
    );
  }

}

export default App;
