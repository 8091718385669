import React from 'react';
// import './About.css';

//scroll top component altered from code pen
// https://codepen.io/Qbrid/pen/GjVvwL

class GoTop extends React.Component {
  constructor(props){
    super();
  }

  componentWillMount(){
    this.setState({ intervalId : 0})
  }

  // $(function(){
  //   $(window).scroll(function(){  //只要窗口滚动,就触发下面代码
  //     var scrollt = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
  //     if( scrollt >300 ){  //判断滚动后高度超过200px,就显示
  //       $("#gotop").fadeIn(400); //淡入
  //     }else{
  //       $("#gotop").stop().fadeOut(400); //如果返回或者没有超过,就淡出.必须加上stop()停止之前动画,否则会出现闪动
  //     }
  //   });
  //   $("#gotop").click(function(){ //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
  //     $("html,body").animate({scrollTop:"0px"},100);
  //   });
  // });


  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }




  handleClick(){
      let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
      this.setState({ intervalId: intervalId });

  }

  render() {
    return (
      <div className="">
        <button title='Back to top' className='scroll'
                onClick={ () => { this.handleClick(); }}>
          <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fgotop.png?alt=media&token=86cccbc6-2737-4592-9d03-bd427a1c95de"/>
        </button>
      </div>

    );
  }


}

export default GoTop;