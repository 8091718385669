import React from 'react';
import '../project.css';
import ScrollTop from "react-scrolltop-button";

class CourseWorkSample extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }

  render() {
    // console.log("thisis blackboard")
    return (

      <div>
        <div className="narrow">
          <p className="projectName">Other works</p>

          <p className="title">Mobile App Development</p>
          <p className="generaltext">
            Ios application worksample<br/>
              Tool: Swift<br/>
              Courselink:  https://www.arl.wustl.edu/~todd/cse438/index.html
          </p>
          <div style = {{ marginTop : -150, marginBottom : -350 }}>
            <iframe src="https://player.vimeo.com/video/251270917?autoplay=1&loop=1&autopause=0" width="300"
                    height="900" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
            <iframe src="https://player.vimeo.com/video/251270941?autoplay=1&loop=1&autopause=0" width="300"
                    height="900" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
            <iframe src="https://player.vimeo.com/video/251270961?autoplay=1&loop=1&autopause=0" width="300"
                    height="900" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
          </div>
        </div>


        <div className="narrow">
          <p className="title">Geek Park</p>
          <p className="generaltext">
            A community for co-working space<br/>
          </p>
        </div>

        <div className="narrow">
            <a>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/WORKSAMPLE%2Fheader.png?alt=media&token=9ce40fd4-732f-4c43-b4cd-b35808f0da5a"/>
            </a>
            <a>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/WORKSAMPLE%2Fwireframing.jpg?alt=media&token=a1dd8a00-c737-45eb-982a-6b5d964fb509"/>
            </a>
            <a>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/WORKSAMPLE%2FHAND.gif?alt=media&token=7803f500-d55e-4e3d-b0ea-db3759a28267"/>
            </a>
        </div>

        <div className="narrow" style = {{ marginTop : 200 }}>
          <p className="title">AR zombie shooting game</p>
          <p className="generaltext">
            Tool: Swift, Unity, C#<br/>
            Team: Benjamin Kim, Wu Yang, Shengmin Huang, Yuxiang Wang
          </p>
              <a>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/WORKSAMPLE%2F33.png?alt=media&token=0138ece9-91fc-40e0-b2b7-d76261eb5740"/>
              </a>
        </div>

        <ScrollTop text="Top"/>

      </div>
    );
  }



}

export default CourseWorkSample;