import React from 'react';
import '../project.css';
import ScrollTop from "react-scrolltop-button";

class AHubAFlagshipStore extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    // console.log("thisis blackboard")
    return (

      <div>


        <div className="narrow">
          <p className="projectName">A hub, a flagship store</p>
          <p className="h2">Rebuilding Infrastructure</p>
          <p className="projectinfo">
            2016 Fall 512 Studio<br/>
            Individual Work<br/>
            Site Location:Hong Kong<br/>
            Professor:Jennifer Yoos
           </p>





  <p className="generaltext">Do government, urban planning department, citizens, enterprises have a common goal? Through the study of Hong Kong's railway development history, negotiating and collaboration between different forces can be an imaginative way of creating new architecture. The site is located in Hong Kong's most popular seafront area. However the infrastucture doesn't have a chance to develope since government and tax payers won't contribute to it even it's for their own sake.
    <br/>

          The proposal is a infrastructure-like architecture that plays a role as transportation hub. It integrates a bus station, a taxi station, the undergound metro system and lead citizens to enjoy the sea view.
    More importantly, there is luxury commercial built into it. In this way, government can largely reduce expenditure by allowing the enterprise to develope this proposal. In return, the future commercial will benefit from this large amount of human flow. It becomes a win-win soluation as it creates a pleasant urban space as well as commercial opportunities.
      Architecturally, it moves two stations undergound to have more  programs on ground level and creates continiuty with undergound. Visitors can access to seafront through exterior ramps and rooftop garden to enjoy seaview from two sides. Flagship store of a luxury brand locates on the second floor. It integrates the underground space through two concourses for pedestrians of faster movement.
  </p>

  </div>

    <div class="narrow">
      <ul class="img_list">
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fpinup926.png?alt=media&token=42479c22-0c35-4e99-b18c-a52a50b5b1ce"/>
            <span class="text-content">CIRCULATION MAPPING</span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fpinup9263.png?alt=media&token=efbecce0-a09f-4b13-b231-606e314d2ca9"/>
            <span class="text-content">SITE ANALYSIS</span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fpinup9262.png?alt=media&token=e18c9bb3-611c-42af-92dd-ac4e2037de99"/>
            <span class="text-content">HONG KONG RAILWAY SYSTEM</span>
          </a>
        </li>

        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2FCD.png?alt=media&token=dfea9257-e0d2-424d-8898-41523be4ff09"/>
            <span class="text-content">CONCEPT DIAGRAM</span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fbirdeye.jpg?alt=media&token=cc4b8b30-dfec-42a8-b66c-b4bb9dedb28f"/>
            <span class="text-content">BIRDEYEVIEW</span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Finterior.jpg?alt=media&token=94876cea-f30e-4c2e-bc70-bac92796edf6" />
            <span class="text-content"><span>INTERIOR VIEW</span></span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fplan1.jpg?alt=media&token=d7d433df-3bbf-4ea1-ba3e-9f3c7029bb7d"/>
            <span class="text-content"><span>FIRST FLOOR PLAN</span></span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fplan2.jpg?alt=media&token=aecacd22-4eff-4b45-bf4a-cf783fe5934d"/>
            <span class="text-content"><span>SECOND FLOOR PLAN</span></span>
          </a>
        </li>
        <li>
          <a>
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/AHUBAFLAGSHIPSTORE%2Fsection.png?alt=media&token=e64b5b53-9e87-4cbf-b62b-bea472d2ba05"/>
            <span class="text-content"><span>SECTIONS</span></span>
          </a>
        </li>


      </ul>
      <img id="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/HomePage%2Fgotop.png?alt=media&token=86cccbc6-2737-4592-9d03-bd427a1c95de"/>
    </div>




        <ScrollTop text="Top"/>

    </div>

  );

  }
}

export default AHubAFlagshipStore;