import React from 'react';
import '../project.css';
import ScrollTop from "react-scrolltop-button";

class UnexpectedCity extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };


  }



  componentWillMount(){
    console.log("mounted")
  }
  render() {
    // console.log("thisis blackboard")
    return (

      <div>

        <div className="narrow">
          <p className="projectName">The undercity</p>
          <p className="title">bufferzones of urbanism</p>
        <br/>


        <p className="projectinfo">2014 Spring<br/>
        Induvidual Work<br/>
    Site Location:Hong Kong<br/>
    Professor:Xu Wei<br/>
  </p>



  <p className="generaltext">

      The Under City is a proposal from the perspective of city development in rapid urbanism process. City renewal and adjustment is phase two after the mass construction. And it is about time for introspection and adoption of new methods:to utilize negative urban space to create multi-function programs and new urban space for the area. Then there's a design proposal to follow up.
    </p>

  </div>

  <div className="narrow">
      <ul className="img_list">
      <li>
      <a>
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Fdrawing.png?alt=media&token=454f955f-b7a0-4bb7-9697-6b2a82697cd2"/>
      <span className="text-content"><span>Slogans for property exchange,Hong Kong</span></span>
    </a>
  </li>
  <br/>
    <p>The cities of human is analogous to ant colonies. The behavior of every individual results in the whole picture of dwellers of which the individual is unconscious. The unawareness of the individual, however, constitutes something complicated yet has specific patterns to study.<br/>However, due to this kind of unawareness, the construction in cities should be controlled before lands become fully occupied.
  <br/><br/><br/>
    </p>

    <li>
      <a >
        <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Flarge.png?alt=media&token=c73e51f5-73ff-4257-9ecd-258eca9353f4"/>
        <span className="text-content"></span>
      </a>
    </li>
    <li>
    <a >
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Flarge2.png?alt=media&token=6f993dcf-62d1-4814-b9b6-ba25800a8780"/>
      <span className="text-content">Diagram</span>
  </a>
  </li>

  <br/><br/>
    <p className= "h1">Unexpected city</p>
    <p className= "title">"WEEK CONNECTION"</p>
  <br/><br/>
    <p>
      This design is located in Hong Kong. As a representative of metropolis, it is ineluctable to push density to a greater extent. Besides, the downtown area in an international city requires a refreshing place for both locals and tourists, since the skyscrapers in adjacent areas all looked time-worn.
      As a follow-up design of the urban issue. A gallery is proposed to replace the single-function plazza.

      The aim of this architecture is to reach unexpectedness and diversity of space. Inspired by  Sou Fujimoto, the space is at presence and detached at the same time. For instance, the architecture beneath the square is a part of it, however it is alienated.
    </p>


    <li>
    <a>
    <img className="left" src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Fdiagram.png?alt=media&token=74971541-e7d3-4b05-9ad8-18f3366baea7"/>
      <span className="text-content"><span>DIAGRAMS</span></span>
    </a>
  </li>

  <li>
    <a>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Fmodel.png?alt=media&token=d7a6c158-6160-4886-84ce-34073b4dc355"/>
      <span className="text-content">MODEL</span>
  </a>
  </li>
    <li>
      <a>
        <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Fsections.png?alt=media&token=031951ce-d793-4433-a9d5-0647990863fd"/>
        <span className="text-content">SECTIONS</span>
      </a>
    </li>
    <li>
    <a>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-a2f3d.appspot.com/o/UNEXPECTEDCITY%2Frender.jpg?alt=media&token=ce2a1920-70f4-4ccd-80a8-19e67ec73c9a"/>
      <span className="text-content"><span>INTERIOR VIEW</span></span>
    </a>
  </li>



  </ul>

  </div>

        <ScrollTop text="Top"/>

    </div>

  );

  }
}

export default UnexpectedCity;